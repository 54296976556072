export const LANGUAGES = {
  pt: {
      urlLang: 'pt',
      code: 'pt-BR'
  },
  en: {
      urlLang: 'en',
      code: 'en-US'
  },
  default: 'pt'
}