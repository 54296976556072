import React, { Component } from "react";
import { FiCode, FiBarChart, FiUsers, FiSmartphone } from "react-icons/fi";
import { FormattedMessage } from "react-intl";
const ServiceList = [
    {
        icon: <FiCode />,
        title: 'services.web',
        description: 'services.web.description'
    },
    {
        icon: <FiSmartphone />,
        title: 'services.apps',
        description: 'services.apps.description'
    },
    {
        icon: <FiBarChart/>,
        title: 'services.digital',
        description: 'services.digital.description'
    },
    {
        icon: <FiUsers />,
        title: 'services.traffic',
        description: 'services.traffic.description'
    }
]

class ServiceTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title"><FormattedMessage id="services.title"/></h2>
                            <p><FormattedMessage id="services.description"/></p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="https://wa.me/5511963097009?text=Quero%20conversar%20sobre%20um%20projeto" target="_blank" rel="noopener noreferrer"><span className="text"><FormattedMessage id="services.callToAction"/></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map((val, i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="https://wa.me/5511963097009?text=Quero%20conversar%20sobre%20um%20projeto" target="_blank" rel="noopener noreferrer">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                            {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title"><FormattedMessage id={val.title}/></h3>
                                                <p><FormattedMessage id={val.description}/></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
