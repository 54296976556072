import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Açocon"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Caldervol"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Aprendendo Desenhar"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Willian Santos"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Viva da Mente"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="The Ultimate Reviews"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="Taverna da Bagunça"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;