import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import Footer from "../component/footer/Footer";

import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import Scrollspy from 'react-scrollspy';

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";

import { FormattedMessage } from 'react-intl';

class MainDemo extends Component {
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }
    render() {

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return (
            <div className="active-dark">
                <Helmet pageTitle="Dark n' Black Interactive Studio" />
                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed color-white">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo.png" alt="Dark and Black Interactive Studio" />
                                    <img className="logo-2" src="/assets/images/logo/logo-symbol.png" alt="Dark and Black Interactive Studio symbol" />
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'about', 'services', 'portfolio', 'contact']} currentClassName="is-current" offset={-100}>
                                    <li><a href="#home"><FormattedMessage id="menu.home" /></a></li>
                                    <li><a href="#about"><FormattedMessage id="menu.about" /></a></li>
                                    <li><a href="#services"><FormattedMessage id="menu.services" /></a></li>
                                    <li><a href="#portfolio"><FormattedMessage id="menu.portfolio" /></a></li>
                                    <li><a href="#contact"><FormattedMessage id="menu.contact" /></a></li>
                                    <li><a href="/pt"><img width="20px" src="/assets/images/flags/brasil.png" alt="Bandeira do Brasil" /></a></li>
                                    <li><a href="/en"><img width="20px" src="/assets/images/flags/usa.png" alt="Bandeira USA" /></a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120 bg_color--1" id="about">
                    <About />
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area ptb--80  bg_image bg_image--3" id="services">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1" id="portfolio">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500"><FormattedMessage id="achievements.title"/></h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation pb--120" id="brand">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="section-title text-center">
                                    <h3 className="fontWeight500"><FormattedMessage id="ourClients.title"/></h3>
                                </div>
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </div>
        )
    }
}
export default MainDemo;